var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "app-form",
    {
      attrs: {
        id: "appointment-search-form",
        submitAction: _vm.search,
        validationOptions: _vm.validationOptions
      }
    },
    [
      _c(
        "div",
        { staticClass: "appointment-start-date" },
        [
          _c("app-datepicker", {
            attrs: {
              id: "startDate",
              placeholder: _vm.$t("calendar.startTime"),
              cssClass: "e-field"
            },
            model: {
              value: _vm.startDate,
              callback: function($$v) {
                _vm.startDate = $$v
              },
              expression: "startDate"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "appointment-end-date" },
        [
          _c("app-datepicker", {
            attrs: {
              id: "endDate",
              placeholder: _vm.$t("calendar.endTime"),
              cssClass: "e-field"
            },
            model: {
              value: _vm.endDate,
              callback: function($$v) {
                _vm.endDate = $$v
              },
              expression: "endDate"
            }
          })
        ],
        1
      ),
      _c(
        "app-button",
        {
          attrs: { cssClass: "e-primary appointment-search" },
          on: {
            click: function($event) {
              return _vm.$emit("search", {
                startDate: _vm.startDate,
                endDate: _vm.endDate
              })
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("statistics.show")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }