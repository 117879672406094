var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h2", [_vm._v(_vm._s(_vm.title))]),
    _c("div", [_c("search-toolbar", { on: { search: _vm.updateCharts } })], 1),
    _c("div", { staticStyle: { "margin-top": "8px" } }, [
      _c("div", { staticClass: "e-btn-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.granularity,
              expression: "granularity",
              modifiers: { number: true }
            }
          ],
          attrs: { type: "radio", id: "day", name: "granularity", value: "1" },
          domProps: { checked: _vm._q(_vm.granularity, _vm._n("1")) },
          on: {
            change: function($event) {
              _vm.granularity = _vm._n("1")
            }
          }
        }),
        _c("label", { staticClass: "e-btn", attrs: { for: "day" } }, [
          _vm._v(_vm._s(_vm.$t("timePeriods.day")))
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.granularity,
              expression: "granularity",
              modifiers: { number: true }
            }
          ],
          attrs: { type: "radio", id: "week", name: "granularity", value: "2" },
          domProps: { checked: _vm._q(_vm.granularity, _vm._n("2")) },
          on: {
            change: function($event) {
              _vm.granularity = _vm._n("2")
            }
          }
        }),
        _c("label", { staticClass: "e-btn", attrs: { for: "week" } }, [
          _vm._v(_vm._s(_vm.$t("timePeriods.week")))
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.granularity,
              expression: "granularity",
              modifiers: { number: true }
            }
          ],
          attrs: {
            type: "radio",
            id: "month",
            name: "granularity",
            value: "3"
          },
          domProps: { checked: _vm._q(_vm.granularity, _vm._n("3")) },
          on: {
            change: function($event) {
              _vm.granularity = _vm._n("3")
            }
          }
        }),
        _c("label", { staticClass: "e-btn", attrs: { for: "month" } }, [
          _vm._v(_vm._s(_vm.$t("timePeriods.month")))
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.granularity,
              expression: "granularity",
              modifiers: { number: true }
            }
          ],
          attrs: { type: "radio", id: "year", name: "granularity", value: "5" },
          domProps: { checked: _vm._q(_vm.granularity, _vm._n("5")) },
          on: {
            change: function($event) {
              _vm.granularity = _vm._n("5")
            }
          }
        }),
        _c("label", { staticClass: "e-btn", attrs: { for: "year" } }, [
          _vm._v(_vm._s(_vm.$t("timePeriods.year")))
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.granularity,
              expression: "granularity",
              modifiers: { number: true }
            }
          ],
          attrs: {
            type: "radio",
            id: "total",
            name: "granularity",
            value: "6"
          },
          domProps: { checked: _vm._q(_vm.granularity, _vm._n("6")) },
          on: {
            change: function($event) {
              _vm.granularity = _vm._n("6")
            }
          }
        }),
        _c("label", { staticClass: "e-btn", attrs: { for: "total" } }, [
          _vm._v(_vm._s(_vm.$t("timePeriods.all")))
        ])
      ])
    ]),
    _c(
      "div",
      [
        _c("customers-acquisition-channels-statistics", {
          ref: "customersAcquisitionChannelsStatistics",
          attrs: { granularity: _vm.granularity }
        }),
        _c("customers-time-slots-statistics", {
          ref: "customersTimeSlotsStatistics",
          attrs: { granularity: _vm.granularity }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }